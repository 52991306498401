import React from "react";
import { renderRoutes } from "react-router-config";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { Routes } from "./routes";
import storage from "./utils/storage";
import { ToastServiceProvider } from "./context/ToastService";

const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL;

// Auth Link
const authLink = setContext((_, { headers }) => {
  const token = storage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Log GraphQL errors
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map((err) => {
      const { message, locations, path } = err;
      let status = err?.extensions?.exception?.status || "";
      if (status === 403 || status === 401) {
        storage.removeItem("token");
        window.location.href = "/login";
      }
      return console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

// GraphQL Link
const link = from([
  authLink,
  errorLink,
  new HttpLink({ uri: GRAPHQL_URL, credentials: "same-origin" }),
]);

// Initialize Apollo Client
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
});

function Root() {
  return (
    <ApolloProvider client={client}>
      <ToastServiceProvider>{renderRoutes(Routes)}</ToastServiceProvider>
    </ApolloProvider>
  );
}

export default Root;
