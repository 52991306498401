import React, { useEffect, useState } from "react";
import { renderRoutes } from "react-router-config";
import { useQuery, gql } from "@apollo/client";

import "./css/app.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import storage from "./utils/storage";
import FallbackLoader from "./components/FallbackLoader";
import AdminContext from "./context/AdminContext";
import { useHistory } from "react-router-dom";

const FETCH_ADMIN = gql`
  query {
    whoTheAdmin {
      name
      email
      gpay
      phonepe
      paytm
      bankName
      accountHolderName
      accountNumber
      ifscCode
    }
  }
`;

function App({ route }) {
  const [admin, setAdmin] = useState("");

  const { loading } = useQuery(FETCH_ADMIN, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setAdmin(data.whoTheAdmin);
    },
  });

  let history = useHistory();

  useEffect(() => {
    let token = storage.getItem("token");
    if (!token) {
      history.push("/login");
    }
    return () => {};
  }, [history, history.location.pathname]);

  if (loading) return <FallbackLoader />;

  return (
    <AdminContext.Provider value={{ admin }}>
      <div className="app">
        <Header />
        <main>{renderRoutes(route.routes)}</main>
        <Footer />
      </div>
    </AdminContext.Provider>
  );
}

export default App;
