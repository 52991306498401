import React from "react";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

function Toast({ open, message, severity, duration, handleClose }) {
  let alertText = message;
  if (!message && severity === "error") {
    alertText = "Something went wrong";
  }
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      onClose={handleClose}
      autoHideDuration={duration}
    >
      <Alert severity={severity} variant="filled" onClose={handleClose}>
        {alertText}
      </Alert>
    </Snackbar>
  );
}

export default Toast;
