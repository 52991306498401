import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLazyQuery, gql } from "@apollo/client";
import {
  Typography,
  Paper,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";
import FallbackLoader from "../components/FallbackLoader";
import SomethingWrong from "../components/SomethingWrong";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ContentCopy, ChevronRight, ChevronLeft } from "mdi-material-ui";
import { addDays, format, set, subDays } from "date-fns";
import Pagination from "@material-ui/lab/Pagination";
import { useToast } from "../context/ToastService";
import { Link } from "react-router-dom";
import currency from "../utils/currency";

const useStyles = makeStyles((theme) => ({
  root: {
    "@media (min-width: 768px)": {
      padding: 12,
    },
  },
  table: {
    width: "100%",
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
  },
  filters: {
    margin: "12px 0",
    padding: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  filter: {
    flex: 1,
    margin: 10,
    display: "flex",
  },
  dateCell: {
    minWidth: 160,
  },
  pagination: {
    margin: "16px 0",
    display: "flex",
    justifyContent: "center",
  },
}));

const TRANSACTION_FRAGMENT = gql`
  fragment TransactionFields on TransactionRequest {
    id
    status
    createdAt
    points
    type
    user {
      id
      phone
      name
    }
    remarks
  }
`;

const GET_TRANSACTIONS = gql`
  ${TRANSACTION_FRAGMENT}
  query GetTransactions($filters: TRFilterInput!) {
    getAllTRs(filters: $filters) {
      transactions {
        ...TransactionFields
      }
      count
    }
  }
`;

function ReferralBonusReports() {
  const classes = useStyles();
  const [filters, setFilters] = useState({
    createdAt: format(new Date(), "yyyy-MM-dd"),
    type: 'DEPOSIT',
    status: "COMPLETED",
    remarks: 'Referral bonus'
  });

  let limit = 25;

  let Toast = useToast();

  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [getTransactions, { loading: getLoading, error }] = useLazyQuery(
    GET_TRANSACTIONS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        //console.log(data.getAllTRs);
        let { transactions, count } = data.getAllTRs;
        setData(transactions);
        setPageCount(Math.ceil(count / limit));
      },
    }
  );

  const handleChangePage = (event, page) => {
    setPage(page);
    setSkip((page - 1) * limit);
  };

  const handleChange = (key, value) => {
    setFilters((prevData) => {
      return { ...prevData, [key]: value };
    });
  };

  const handleChangeDate = (type) => {
    const newFilters = {
      ...filters,
    }
    if (type === 'next') {
      newFilters.createdAt = format(addDays(new Date(newFilters.createdAt), 1), "yyyy-MM-dd");
    } else {
      newFilters.createdAt = format(subDays(new Date(newFilters.createdAt), 1), "yyyy-MM-dd");
    }

    setFilters(newFilters);
  }

  const fetchTransactions = useCallback(() => {
    let filteredFilters = { ...filters };
    if (filteredFilters.createdAt) {
      filteredFilters.createdAt = set(new Date(filteredFilters.createdAt), {
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });
    }
    getTransactions({
      variables: {
        filters: { where: { ...filteredFilters }, skip, take: limit },
      },
    });
  }, [filters, skip, limit, getTransactions]);

  const copyToClipboard = (text) => {
    Toast("Copied to clipboard!", "success", 1000);
    navigator?.clipboard?.writeText(text);
  };

  useEffect(() => {
    fetchTransactions();
    return () => { };
  }, [fetchTransactions]);

  if (getLoading) return <FallbackLoader />;

  if (error) return <SomethingWrong />;

  return (
    <div className={classes.root}>
      <Typography variant="h6">Referral Bonus Reports</Typography>
      <Paper className={classes.filters}>
        <IconButton color="primary" onClick={() => handleChangeDate('prev')}>
          <ChevronLeft />
        </IconButton>
        <div className={classes.filter}>
          <TextField
            fullWidth
            label="Date"
            type="date"
            value={filters.createdAt}
            onChange={(e) => handleChange("createdAt", e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <IconButton color="primary" onClick={() => handleChangeDate('next')}>
          <ChevronRight />
        </IconButton>
      </Paper>
      <Paper>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Points</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell className={classes.dateCell}>Date & Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((tr) => (
                <TableRow key={"tr-" + tr.id}>
                  <TableCell>{tr.user.id}</TableCell>
                  <TableCell>
                    <Link to={`/users/${tr.user.id}`}>{tr.user.name}</Link>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => copyToClipboard(tr.user.phone)}
                      endIcon={<ContentCopy />}
                    >
                      {tr.user.phone}
                    </Button>
                  </TableCell>
                  <TableCell>{currency(tr.points)}</TableCell>
                  <TableCell>{tr.remarks}</TableCell>
                  <TableCell>
                    {format(new Date(tr.createdAt), "dd-MMM-yy, hh:mm a")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <div className={classes.pagination}>
        <Pagination
          count={pageCount}
          page={page}
          onChange={handleChangePage}
          color="primary"
        />
      </div>
    </div>
  );
}

export default ReferralBonusReports;
