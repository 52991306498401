import React, { useContext, useState } from "react";
import Toast from "../components/Toast";

const ToastServiceContext = React.createContext(() => {});

export const useToast = (message, severity, duration) =>
  useContext(ToastServiceContext);

export const ToastServiceProvider = ({ children }) => {
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("success");
  const [toastDuration, setToastDuration] = useState(2000);
  const openToast = (message, severity = "success", duration = 2000) => {
    setToastOpen(true);
    setToastMessage(message);
    setToastSeverity(severity);
    setToastDuration(duration);
  };
  const closeToast = () => setToastOpen(false);

  return (
    <>
      <ToastServiceContext.Provider value={openToast} children={children} />
      <Toast
        open={toastOpen}
        message={toastMessage}
        severity={toastSeverity}
        duration={toastDuration}
        handleClose={closeToast}
      />
    </>
  );
};
