import {
  AccountMultipleOutline,
  CashCheck,
  ClipboardListOutline,
  ClipboardTextOutline,
  FormatListChecks,
  GamepadVariantOutline,
  MonitorDashboard,
  ShieldAccountOutline,
  TagTextOutline,
  TextBoxCheckOutline,
  CardText,
  MedalOutline
} from "mdi-material-ui";

const NAV_ITEMS = [
  {
    route: "/",
    text: "Dashboard",
    icon: MonitorDashboard,
  },
  {
    route: "/markets",
    text: "Markets",
    icon: GamepadVariantOutline,
  },
  {
    route: "/results",
    text: "Results",
    icon: TextBoxCheckOutline,
  },
  {
    route: "/wallet-requests",
    text: "Wallet Requests",
    icon: CashCheck,
  },
  {
    route: "/reports",
    text: "Reports",
    icon: ClipboardListOutline,
  },
  {
    route: "/referral-bonus-reports",
    text: "Referral Bonus Reports",
    icon: MedalOutline
  },
  {
    route: "/users",
    text: "Users",
    icon: AccountMultipleOutline,
  },
  {
    route: "/admin-details",
    text: "Admin Details",
    icon: ShieldAccountOutline,
  },
  {
    route: "/game-rates",
    text: "Game Rates",
    icon: TagTextOutline,
  },
  {
    route: "/notice-board",
    text: "Notice Board",
    icon: ClipboardTextOutline,
  },
  {
    route: "/home-notice-board",
    text: "Home Notice Board",
    icon: CardText,
  },
  {
    route: "/how-to-play",
    text: "How To Play",
    icon: FormatListChecks,
  },
];

export default NAV_ITEMS;
