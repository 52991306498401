import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { AccountCircle, Menu as MenuIcon } from "mdi-material-ui";
import useAuth from "../hooks/useAuth";
import NAV_ITEMS from "../shared/NavItems";
import colors from "../shared/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  titleBlock: {
    flexGrow: 1,
  },
  title: {
    color: colors.white,
    textDecoration: "none",
  },
  list: {
    width: 250,
  },
}));

function Header() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const menuOpen = Boolean(anchorEl);

  const auth = useAuth();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.titleBlock}>
          <Typography
            component={Link}
            to="/"
            variant="h6"
            className={classes.title}
          >
            Games - Admin
          </Typography>
        </div>
        <div>
          <IconButton onClick={handleMenu} color="inherit">
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={menuOpen}
            onClose={handleClose}
          >
            <MenuItem component={Link} to="/profile" onClick={handleClose}>
              My Profile
            </MenuItem>
            <MenuItem onClick={() => auth.logout()}>Logout</MenuItem>
          </Menu>
        </div>
        <Drawer anchor="left" open={isOpen} onClose={toggleDrawer}>
          <div className={classes.list}>
            <List>
              {NAV_ITEMS.map((item, index) => {
                let Icon = item.icon;
                return (
                  <ListItem
                    key={item.text + index}
                    button
                    component={Link}
                    to={item.route}
                    onClick={toggleDrawer}
                  >
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                );
              })}
            </List>
          </div>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
