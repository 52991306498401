import { lazy } from "react";

import App from "./App";
import ReferralBonusReports from "./pages/ReferralBonusReports";
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Login = lazy(() => import("./pages/Login"));
const Markets = lazy(() => import("./pages/Markets"));
const Results = lazy(() => import("./pages/Results"));
const Reports = lazy(() => import("./pages/Reports"));
const ReportOverview = lazy(() => import("./pages/ReportOverview"));
const RevenueReports = lazy(() => import("./pages/RevenueReport"));
const WalletRequests = lazy(() => import("./pages/WalletRequests"));
const AdminDetails = lazy(() => import("./pages/AdminDetails"));
const MyProfile = lazy(() => import("./pages/MyProfile"));
const NotFound = lazy(() => import("./pages/NotFound"));
const AddMarket = lazy(() => import("./pages/AddMarket"));
const Users = lazy(() => import("./pages/Users"));
const User = lazy(() => import("./pages/User"));
const NoticeBoard = lazy(() => import("./pages/NoticeBoard"));
const GameRates = lazy(() => import("./pages/GameRates"));
const HowToPlay = lazy(() => import("./pages/HowToPlay"));
const HomeScreenNotification = lazy(() => import("./pages/HomeScreenNotification"));

export const Routes = [
  {
    path: "/login",
    component: Login,
    exact: true,
  },
  {
    path: "/404",
    component: NotFound,
    exact: true,
  },
  {
    path: "/",
    component: App,
    routes: [
      {
        path: "/",
        component: Dashboard,
        exact: true,
      },
      {
        path: "/markets",
        component: Markets,
        exact: true,
      },
      {
        path: "/markets/add",
        component: AddMarket,
      },
      {
        path: "/markets/edit/:id",
        component: AddMarket,
      },
      {
        path: "/results",
        component: Results,
      },
      {
        path: "/reports",
        component: Reports,
        exact: true,
      },
      {
        path: "/reports/:gameId",
        component: ReportOverview,
      },
      {
        path: "/referral-bonus-reports",
        component: ReferralBonusReports,
        exact: true,
      },
      {
        path: "/revenue-reports",
        component: RevenueReports,
        exact: true,
      },
      {
        path: "/wallet-requests",
        component: WalletRequests,
      },
      {
        path: "/admin-details",
        component: AdminDetails,
      },
      {
        path: "/profile",
        component: MyProfile,
      },
      {
        path: "/users",
        component: Users,
        exact: true,
      },
      {
        path: "/users/:id",
        component: User,
      },
      {
        path: "/notice-board",
        component: NoticeBoard,
      },
      {
        path: "/game-rates",
        component: GameRates,
      },
      {
        path: "/how-to-play",
        component: HowToPlay,
      },
      {
        path: "/home-notice-board",
        component: HomeScreenNotification,
      },
      {
        component: NotFound,
      },
    ],
  },
];
