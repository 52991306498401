const currency = (number) => {
  if (!number) number = 0;
  return number.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

export default currency;
