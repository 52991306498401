import { red } from "@material-ui/core/colors";
import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";
import colors from "./shared/colors";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: "#fff",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: ['"Muli"', "sans-serif"],
  },
});

export default theme;
