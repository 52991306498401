import { useHistory } from "react-router-dom";

const useAuth = () => {
  const history = useHistory();

  const getToken = () => localStorage.getItem("token");

  const setToken = (token) => {
    localStorage.setItem("token", token);
    history.push("/");
  };

  const logout = () => {
    localStorage.removeItem("token");
    history.push("/login");
  };

  return {
    getToken,
    setToken,
    logout,
  };
};

export default useAuth;
