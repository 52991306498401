import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function FallbackLoader(props) {
  const { height = "100vh", width = "100%" } = props;
  const classes = useStyles();
  return (
    <div className={classes.root} style={{ height, width }}>
      <CircularProgress />
    </div>
  );
}

export default FallbackLoader;
